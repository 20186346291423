<template>
    <div class="main">
        <div class="my-swipe-box">
            <van-swipe :autoplay="3000" lazy-render :height="200" style="padding:0 5px;"> 
                <van-swipe-item v-for="(image,index) in banners" :key="image">
                    <img :src="image.imgs" style="width:97%;height:85%;border-radius: 15px; box-shadow:#ddd 1px 4px 5px 1px;" @click="banner_show_Click(index)"/>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div>
            <van-grid :border="false" center :icon-size="32" :column-num="3">
                <van-grid-item :icon="require('@/assets/img/wdgg@3x.png')" text="我的公告" @click="wdggClick" />
                <van-grid-item :icon="require('@/assets/img/znzy@3x.png')" text="智能指引" @click="znzyClick"/>

<!--                <van-grid-item :icon="require('@/assets/img/lbxx@3x.png')" text="老板学习"-->
<!--                    url="https://wx84cc4d5af40cdfe7.h5.xiaoe-tech.com/homepage?share_user_id=u_5e7c9338d5aa5_0Esz6uMTgq&entry=2&entry_type=2001" />-->

                <van-grid-item :icon="require('@/assets/img/znbs@3x.png')" text="智能办税" @click="znbsClick"/>
            </van-grid>
            <van-grid class="panel_nssb" v-show="nssb_show" :border="false" center :icon-size="25" :column-num="2" style="background: #F7F8FA; margin-bottom: 10px;">
              <van-grid-item :icon="require('@/assets/img/znbs@3x.png')" text="申报信息" @click="sbmxCik"/>
              <van-grid-item :icon="require('@/assets/img/znbs@3x.png')" text="个体户一键申报" @click="nssbGthClick"/>
<!--              <van-grid-item :icon="require('@/assets/img/znbs@3x.png')" text="小规模简易申报" @click="nssbXgmClick"/>-->
            </van-grid>
            <van-grid :border="false" center :icon-size="32" :column-num="3">
                <van-grid-item :icon="require('@/assets/img/swtj@3x.png')" text="税务体检" @click="swtjClick"/>
                <van-grid-item :icon="require('@/assets/img/ydkp@3x.png')" text="开票服务" @click="ydkpClick" v-if="isShowJCe"/>

            </van-grid>
        </div>
        <div style="margin-top: 15px;">
          <div class="van-grid-item__text" style="margin-bottom: 5px; margin-left: 10px; ">企盈多企业共享服务平台</div>
          <img src="../../assets/img/qydjs@3x.png" style="width: 100%; box-shadow:2px 2px 8px #eee;"/>
        </div>
<!--        <div class="yuancard">-->
<!--            <div style="padding:15px 20px;">-->
<!--                <van-row style="margin-bottom:10px; font-size:17px;font-weight:500;">-->
<!--                    <van-col>{{sbxxdata.merchant_name}}</van-col>-->
<!--                </van-row>-->
<!--                <van-row class="huizi">-->
<!--                    <van-col span="8" class="colmin">本月开票份数：</van-col>-->
<!--                    <van-col span="12" v-if="kpxxData.PositiveInvoiceCount!=null">-->
<!--                        <span class="lv">{{kpxxData.PositiveInvoiceCount+kpxxData.NegativeInvoiceCount}}</span>-->
<!--                        (<span class="hong">{{kpxxData.InvalidatedPositiveInvoiceCount+kpxxData.InvalidatedNegativeInvoiceCount}}</span>)-->
<!--                    </van-col>-->
<!--                    <van-col v-else>数据正在计算中...</van-col>-->
<!--                </van-row>-->
<!--                <van-row class="huizi">-->
<!--                    <van-col span="8" class="colmin">本月开票金额：</van-col>-->
<!--                    <van-col span="12">-->
<!--                        <span v-if="kpxxData.hj_PosInv!=null">{{kpxxData.hj_PosInv+kpxxData.hj_InvalidatedPosInv}}</span>-->
<!--                        <span v-else>数据正在计算中...</span>-->
<!--                    </van-col>-->
<!--                </van-row>-->
<!--                <van-row class="huizi">-->
<!--                    <van-col span="8" class="colmin">本月红冲金额：</van-col>-->
<!--                    <van-col span="12">-->
<!--                        <span v-if="kpxxData.hj_NegInv!=null">{{kpxxData.hj_NegInv+kpxxData.hj_InvalidateNegInv}}</span>-->
<!--                        <span v-else>数据正在计算中...</span>-->
<!--                    </van-col>-->
<!--                </van-row>-->
<!--&lt;!&ndash;                <van-row class="huizi">&ndash;&gt;-->
<!--&lt;!&ndash;                    <van-col span="8" class="colmin" style="color: #1989fa;">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span style="text-decoration:underline;">申报状态</span>：&ndash;&gt;-->
<!--&lt;!&ndash;                    </van-col>&ndash;&gt;-->
<!--&lt;!&ndash;                    <van-col span="12">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-if="sbxxdata.djxh==''">绑定企业...</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-else-if="sydata.hzData.SBZT==undefined">加载中...</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="lv" v-else-if="sydata.hzData.SBZT=='申报成功'">{{sydata.hzData.SBZT}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-else>{{sydata.hzData.SBZT}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </van-col>&ndash;&gt;-->

<!--&lt;!&ndash;                </van-row>&ndash;&gt;-->
<!--&lt;!&ndash;                <van-row class="huizi">&ndash;&gt;-->
<!--&lt;!&ndash;                    <van-col span="8" class="colmin" style="color: #1989fa;">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span style="text-decoration:underline;">缴款金额</span>：&ndash;&gt;-->
<!--&lt;!&ndash;                    </van-col>&ndash;&gt;-->
<!--&lt;!&ndash;                    <van-col span="12">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-if="sbxxdata.djxh==''">绑定企业...</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-else-if="sydata.hzData.YJSK!=null">{{sydata.hzData.YJSK}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-else>加载中...</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </van-col>&ndash;&gt;-->
<!--&lt;!&ndash;                </van-row>&ndash;&gt;-->

<!--                <van-button style="margin-top:8px;padding:0 8px;" size="mini" color="#6889F6" round  type="primary" @click="yhqhClick()">用户切换</van-button>-->
<!--            </div>-->
<!--        </div>-->
        <div>
            <van-popup v-model:show="ztshow" round closeable>
                <div style="min-width:90vw;min-height:45vh;">
                    <van-cell-group style="padding-top:50px;">
                        <van-cell title="税种" value="内容" />
                        <van-cell title="纳税期限" value="内容" />
                        <van-cell title="申报状态" value="内容" />
                        <van-cell title="缴款状态" value=""/>
                        <van-cell title="缴款金额" value=""/>
                    </van-cell-group>
                </div>
            </van-popup>

            <van-popup v-model:show="qyxxshow" :style="{ width: '90%'  }"  >
                <van-cell-group>
                    <van-cell center v-for="item in nsrArr" :key = item :title="item.merchant_name" @click="xzClick(item)">
                        <template #right-icon>
                            <van-icon v-if= "item.mryh === item.merchant_id" name="passed"/>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-popup>
        </div>


    </div>
    <Footer></Footer>
    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false" >
      <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>
</template>

<script>
import Footer from "../../components/Tabbar/index.vue";
import { Toast} from 'vant';
import getUserInfo from '@/mixin/getUserInfo.js'
import {defineComponent, ref, getCurrentInstance, computed ,onMounted} from 'vue'
import {useStore} from 'vuex';
import loginRequest from '../../api/login'
export default defineComponent({
    name: "hull",
    components: {
        Footer
    },
    setup() {
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const banners = [
          // {show:true,title:"发票服务",imgs:require('../../assets/img/banner01@3x.png'),
          //     tms:[{type:"txt",data:"快速便捷",style:""}]},
          {show:true,title:"盘柜",imgs:require('../../assets/img/banner01-pg@3x.png') ,
              tms:[{type:"img",data:"bms/kpfw-uk-pg-01.jpg"},{type:"img",data:"bms/kpfw-uk-pg-02.jpg"}]},
          {show:true,title:"UKEY盒子",imgs:require('../../assets/img/banner01-uk@3x.png') ,
              tms:[{type:"img",data:"bms/kpfw-uk-box-01.jpg"},{type:"img",data:"bms/kpfw-uk-box-02.jpg"}]},
          // {show:false,title:"智能指引",imgs:require('../../assets/img/banner02@3x.png') ,
          //     tms:[]},
          {show:false,title:"师爷说",imgs:require('../../assets/img/banner03@3x.png') ,
              tms:[]},
        ]
        const isShowJCe = ref(false);
        // console.log(sessionStorage.getItem('isJianCe'))
        if(sessionStorage.getItem('isJianCe')=='0'){
            isShowJCe.value=true;
        } else{
            isShowJCe.value=false;
        }
        
        const ydkpClick = () =>{
            proxy.$root.$router.push({path: "/fpgl"});
        };
        const znzyClick = () =>{
            proxy.$root.$router.push({path: "/guidance"});
        };
        const bossClick = () =>{
            proxy.$root.$router.push({path: "/boss"});
        };
        const banner_show_Click = (index) =>{
            let tparams =  banners[index];
            if(tparams.show){
              tparams.imgs="";
              proxy.$root.$router.push({name: "banner_show", params:{tpms:JSON.stringify(tparams)}});
            }
        };

        const sbxxdata =ref({});
        const znbsClick = () =>{

          if(nssb_show.value){//
            nssb_show.value = false;
          }else{
            sbxxReq(function(){
              let t_djxh = sbxxdata.value.djxh;
              if(t_djxh!=undefined && t_djxh!=""){
                nssb_show.value = true;
              }else{
                //Toast("请先验证企业信息");
                sessionStorage.setItem('sy-sbxx-data',JSON.stringify(sbxxdata.value));
                proxy.$root.$router.push({path: "/intellectBS"});
              }
            });
          }
        };
        const ztshow = ref(false);
       
        const kpxxData = computed(() => store.state.sykpxx);
       
        console.log(kpxxData);
        console.log(kpxxData.value.InvalidatedNegativeInvoiceCount);
        
        const sydata = ref({
            bykpfs:0,
            byfs2:0,
            bykpje:0,
            byje2:0,
            byhcje:0,
            byhc2:0,
            jkje:0,
            hzData:{}
        });
        
        const sbxxReq = (t_callback) =>{
            showload.value = true;
            loginRequest.yh_sbxx({}).then(res=>{
                if (res.returnCode === "00"){
                    sbxxdata.value = res.rows;
                    sydata.value.hzData = res.rows.hzData[0];
                }
                showload.value = false;
                t_callback();
            });
        };

        const qyxxshow = ref(false);
        const nsrArr = computed(() => store.state.nsrUserArr);
         const yhqhClick = () =>{
            qyxxshow.value = true;
        };
        const xzClick = (item)=>{
            if (item.merchant_id === item.mryh) {
                return;
            }
            loginRequest.wx_user_modify_mryh({mryh: item.merchant_id}).then(res=>{
                if (res.returnCode === "00"){
                    qyxxshow.value = false;
                    getUserInfo(proxy.$root.$route, store);
                    sbxxReq(function(){});
                }
                
            });
        };
        // const dsq = setInterval(() =>{
        //     if(sessionStorage.getItem("isDenglu")=="true"){
        //         sbxxReq();
        //         window.clearInterval(dsq);
        //     }
        // },1000);
        const sbmxCik = () =>{
            if(sbxxdata.value.djxh == ""){
                // Toast("请先验证企业信息");
                sessionStorage.setItem('sy-sbxx-data',JSON.stringify(sbxxdata.value));
                proxy.$root.$router.push({path: "/intellectBS"});
            }else{
                proxy.$root.$router.push({path: "/sbmx"});
                
            }
        };


        const wdggClick = () =>{
          proxy.$root.$router.push({path: "/wdgg"});
        };

        const nssb_show = ref(false);
        const nssbGthClick = () =>{
          // Toast("功能建设中");
          loginRequest.dsf_jysb({}).then(res=>{
            if (res.returnCode === "00"){
              let t_url = res.rows;
              // let t_url = "https://wx84cc4d5af40cdfe7.h5.xiaoe-tech.com/homepage?share_user_id=u_5e7c9338d5aa5_0Esz6uMTgq&entry=2&entry_type=2001";
              // let t_url = "https://www.baidu.com/";
              // t_url = "https://www.qiyingduo.com/jysb/enter?d=ntt44B50b442z%2FStTeaZLOzjOEQ4VOsonh%2BCS8HJHKh0W6BCswu06oaM%2BPDQ8fyJ4Kdo7ujZ%2B%2BeJaW4TQwgJUbdFwmqsfyH95moeMaTsM10%3D";
              // console.log(t_url);
              proxy.$root.$router.push({name: "boss", params:{tpms:JSON.stringify({url:t_url})}});
              // window.location.href = t_url;
            }else{
              Toast(res.returnMessage);
            }
          });
        };
        const nssbXgmClick = () =>{
          Toast("功能开发中，敬请期待");
          // proxy.$root.$router.push({path: "/guidance"});
        };

        const showload = ref(false);

        const swtjClick = () =>{
            sbxxReq(function(){
              let t_djxh = sbxxdata.value.djxh;
              if(t_djxh!=undefined && t_djxh!=""){
                proxy.$root.$router.push({path: "/swtj"});
              }else{
                //Toast("请先验证企业信息");
                sessionStorage.setItem('sy-sbxx-data',JSON.stringify(sbxxdata.value));
                proxy.$root.$router.push({path: "/intellectBS"});
              }
            });
        };

        onMounted(()=>{
            // setTimeout(() =>{
            // },1000)
        });

        return {
            ydkpClick,
            yhqhClick,
            bossClick,
            banner_show_Click,
            ztshow,
            kpxxData,
            sydata,
            sbxxReq,
            znbsClick,
            xzClick,
            qyxxshow,
            nsrArr,
            sbxxdata,
            znzyClick,
            // dsq,
            sbmxCik,
            banners,
            nssb_show,
            nssbGthClick,
            nssbXgmClick,
            wdggClick,
            showload,
            swtjClick,
            isShowJCe
        };
    },
});
</script>

<style scoped>

    .main {
        /* background:#eee; */
        background-image: url('../../assets/img/bj@3x.png');
        background-repeat:  no-repeat;
        background-size:100% 18%;
        margin: 0;
        padding:50px 10px 10px;
        min-height: 100vh;
    }
    .my-swipe-box {
        min-height: 15%;
        width: 100%;
        /* margin-bottom: 9px; */
        border-radius: 6px;
        overflow: hidden;
        transform: translateY(0);
        /* margin: 10px -10px; */
    }
    .yuancard {
        width: 97%;
        min-height: 15%;
        margin: 20px 5px 70px 5px;;
        background: #F1F4FF right bottom ;
        background-image: url('../../assets/img/kpxxbj@3x.png');
        background-size: 40% 50%;
        background-repeat: no-repeat;
        border-radius: 10px;
        box-shadow: 4px 4px 12px #ddd;
    }
    .huizi{
        color: #666;
        font-size: 13px;
        margin: 6px 0;
    }
    .colmin{
        min-width: 90px;
    }
    .lv{
        color: green;
    }
    .hong{
        color: red;
    }
    .panel_nssb >>> .van-grid-item__content {
          background: #F7F8FA;
    }
</style>