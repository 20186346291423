<template>
    <van-tabbar route v-model="tabActive" class="dibu"  @change="onTabChange" :border="false">
        <van-tabbar-item replace to="/hull">
            <span>首页</span>
            <template #icon="props">
                <img :src="props.active ? icon.syactive : icon.syinactive" />
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/fpgl" v-if="isShowJCe">
            <img src='../../assets/img/kp@3x.png' class="kpimg"/>
        </van-tabbar-item>
        <van-tabbar-item replace to="/mine">
            <span>我的</span>
            <template #icon="props">
                <img :src="props.active ? icon.active : icon.inactive" />
            </template>
        </van-tabbar-item>
    </van-tabbar>

</template>

<script>
import {defineComponent, reactive, ref, getCurrentInstance} from 'vue'

    export default {
        // props:['tabActive'],
        setup() {
            const {proxy} = getCurrentInstance();
            const tabActive = ref(0);
            const icon = {
                active: require('../../assets/img/wd@3x.png'),
                inactive: require('../../assets/img/wdwx@3x.png'),
                syactive: require('../../assets/img/sy@3x.png'),
                syinactive: require('../../assets/img/sywx@3x.png'),
                
            };
            
            const onTabChange = (index) =>{
            }; 

            const isShowJCe = ref(false);
            console.log(sessionStorage.getItem('isJianCe'))
            if(sessionStorage.getItem('isJianCe')=='0'){
                isShowJCe.value=true
            } else{
                isShowJCe.value=false
            }
            console.log(isShowJCe)
            return {
                tabActive,
                icon, 
                onTabChange,
                isShowJCe
            };
        }
    }
</script>

<style scoped>
    .dibu{
        border-top: #eee solid 1px;
        box-shadow: 0 -4px 5px -3px #eee;

    }
    .kpimg{
        width:60px;
        margin-bottom:29px;
        z-index:2;
    }
</style>